import React, { Component } from "react";
import "./Message.css";

class Message extends Component {
    render() {
        return(
            <div className="wrapper">
                {this.props.error && (
                    <div className="messages">{this.props.error}</div>
                )}
            </div>
        );
    }
}

export default Message;