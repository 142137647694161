import React, { useState, useLayoutEffect } from "react";
import "./VaccinationStatusIndicator.css";
import MaskedInput from "react-text-mask";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import pym from "pym.js";


import Response from "./Response"
import Faq from "./Faq"
import ResetForm from "./ResetForm"
import Message from "./Message"

export const VaccinationStatusIndicator = (props) => {
  const pymChild = new pym.Child();
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState(false);
  const [showPreamble, setShowPreamble] = useState(true);
  const [phnError, setPhnError] = useState(false);
  const [phnValue, setPhnValue] = useState('_ _ _ _ - _ _ _ - _ _ _');
  const [monthValue, setMonthValue] = useState(0);
  const [dateValue, setDateValue] = useState(1);
  const [yearValue, setYearValue] = useState(2019);
  const [dob, setDob] = useState(0);

  useLayoutEffect(() => {
    pymChild.sendHeight();
    }, [pymChild]
  );

  const getListOfMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
  ];

  const resetForm = (action_word) => {
    setSubmitted(false);
    setShowPreamble(true);
    if(action_word === 'preserve') {
      return;
    }
    if(action_word === 'reset') {
      setPhnValue('_ _ _ _ - _ _ _ - _ _ _');
      setDateValue(1);
      setMonthValue(0);
      setYearValue(2019);
    }
  };

  // Removes spaces, dashes, underscores.
  const processPhn = (phn) => {
    return phn.replace(/\s/g, "").replace(/-/g, "").replace(/_/g, "");
  }
  
  const validatePhn = (phn) => {
    if(!phn) {
      return false;
    }
    const weights = [0, 2, 4, 8, 5, 10, 9, 7, 3, 0];
    const digits = Array.from(phn.toString()).map(Number);
    let sum = 0;
    for (let i = 0; i < 10; i++) {
      const product = (parseInt(digits[i]) * parseInt(weights[i]));
      const productRemainder = product % 11;
      sum += productRemainder;
    }
    const sumRemainder = sum % 11;
    const checkDigit = 11 - sumRemainder;
    if(checkDigit === digits[9]){
      return true;
    }
    return false;
  }

  const setFormError = (message, phnInput) => {
    setPhnError(message);
    phnInput.classList.add("form-error");
    return false;
  }
  
  const unsetFormError = (phnInput) => {
    setPhnError(false);
    phnInput.classList.remove("form-error");
  }
  
  const validateForm = (phn) => {
    const phnInput = document.getElementById("phn-input");
    if(!phn) {
      return setFormError("You must include a Personal Health Number in your request", phnInput);
    }
  
    if(phn.length < 10) {
      return setFormError("A valid Personal Health Number has 10 digits. Please check the number and try again", phnInput);
    }
    if(!validatePhn(phn)) {
      return setFormError("The Personal Health Number you submitted is invalid. Please check the number and try again.", phnInput);
    }

    unsetFormError(phnInput);
    return true;
  };

  const pad = (d) => {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  const handleSubmit = (event) => {
    const phn = processPhn(document.getElementById("phn-input").value);
    const dateInputValue = document.getElementById("date-input").value;
    const monthInputValue = document.getElementById("month-input").value;
    const monthName = getListOfMonths[monthInputValue];
    const yearInputValue = document.getElementById("year-input").value;
    const dateString = `${monthName} ${dateInputValue}, ${yearInputValue}`;
    const fullDate = new Date(dateString);
    const year = fullDate.getFullYear();
    const month = fullDate.getMonth() + 1;
    const date = fullDate.getDate();

    const dob = `${year}${pad(month)}${pad(date)}`;
   
    if (validateForm(phn)) {
      setSubmitted(true);
      fetch('/post.php', {
        method: 'POST',
        body: JSON.stringify({
          "phn": phn,
          "dob": dob
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json())
      .then((json) => {
        setData(json)
        setDob(dateString);        
        setDateValue(date);
        setMonthValue(monthInputValue);
        setYearValue(year);
        setShowPreamble(false);
      });
    }
    event.preventDefault();
  };

  const currentYear = new Date().getFullYear();
  const dates = [...Array(31).keys()].map(x => ++x);
  const years = [...Array(40).keys()].map(x => currentYear - x);

  const monthOptions = getListOfMonths.map((x, index) => {
    return <option key={x} value={index}>{x}</option>;
  });
  const dateOptions = dates.map(x => {
    return <option key={x}>{x}</option>;
  });
  const yearOptions = years.map(x => {
    return <option key={x}>{x}</option>;
  });

  return (
    <div className="app">
      <div className="preamble">
        <div className="title"><h2>Vaccination Status Indicator</h2></div>
        {showPreamble && (
          <div>
            <div className="body">
              <p>Parents and guardians are expected to provide public health with immunization records for students enrolled in the provincial school system. This collection of immunization records by public health is supported by the new Vaccination Status Reporting Regulation that came into effect on July 1, 2019.</p>
              <p>Use the Vaccine Status Indicator tool to determine if public health already has your child's immunization record. This tool only confirms that the provincial immunization registry contains your child's record. It does not provide specific information about your child's immunizations.</p>
              <p>Note: No details of the immunization record are available on this website. No data entered (PHN and date of birth) on this website will be saved. The Provincial Health Services Authority Privacy Services Branch has confirmed that this process and associated web interface fully comply with the Freedom of Information and Protection of Privacy Act.</p>
            </div>
          </div>
          )}
        </div>
        {!submitted && (          
          <form id="form" className="form" onSubmit={(e) => handleSubmit(e)}>
            <Message error={phnError} />
            <label>Child's date of birth</label>
            <div className="form-group">
              <div className="month">
                <select id="month-input" name="month" value={monthValue} onChange={(e) => setMonthValue(e.target.value)}>
                  {monthOptions}
                </select>
                <br />
                <label htmlFor="month">Month</label>
              </div>
              <div className="date">
                <select id="date-input" name="date" value={dateValue} onChange={(e) => setDateValue(e.target.value)}>
                  {dateOptions}
                </select>
                <br />
                <label htmlFor="date">Day</label>
              </div>
              <div className="year">
                <select id="year-input" name="year" value={yearValue} onChange={(e) => setYearValue(e.target.value)}>
                  {yearOptions}
                </select>
                <br />
                <label htmlFor="year">Year</label>
              </div>
            </div>
            <label>Child's Personal Health (BC Services Card) Number</label>
            <div className="form-group">
              <MaskedInput
                mask={[
                  /[1-9]/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  "-",
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  "-",
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/
                ]}
                placeholder="_ _ _ _ - _ _ _ - _ _ _"
                guide={true}
                id="phn-input"
                inputMode="numeric"
                value={phnValue}
                onChange={(e) => setPhnValue(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="recaptcha">
              <GoogleReCaptchaProvider reCaptchaKey="6LfcNLAUAAAAAOhQ2pgEY4B9QaGXe-eKsUArF0nH">
                <GoogleReCaptcha />
              </GoogleReCaptchaProvider>
            </div>
            <div>
              <input
                type="submit"
                id="submit"
                value="Look up record"
              />
            </div>            
          </form>          
        )}
        {submitted && showPreamble && (
          <div className="loading"><div className="gif"></div></div>
        )}
        {submitted && !showPreamble && (
          <div>
            <Response data={data} reset={resetForm} months={getListOfMonths} formattedDob={dob} phn={processPhn(phnValue)} />
            <ResetForm reset={resetForm} pym={pymChild} data={data} />
          </div>
        )}
        <Faq pym={pymChild}></Faq>
      </div>      
    );
}

export default VaccinationStatusIndicator;