import React from 'react'
import './ResetForm.css'

const ResetForm = (props) => {
    let buttonLabel = '';
    let action_word = 'reset';
    if(props.data.FullName) {
        buttonLabel = "Look up another record";
    }
    else {
        buttonLabel = "Go back and edit"
        action_word = 'preserve';
    }
    return (
        <div className="post-actions">
            <a className="button" href="#top" onClick={() => props.pym.navigateParentTo('https://immunizebc.ca/finder')}>Find nearest health unit</a>
            <a className="button ghost external" onClick={(e) => props.reset(action_word)} href="#top">{buttonLabel}</a>
        </div>
    );
}

export default ResetForm;