import React, { Component } from "react";
import "./Faq.css";

class Faq extends Component {
    goToLink = (e, link) => {
        e.preventDefault();
        this.props.pym.navigateParentTo(link);
    }
    render() {
        return(
            <div className="faq-wrapper">
                <p>
                    <a href="https://immunizebc.ca/vaccination-status-reporting-regulation" onClick={(e) => this.goToLink(e, 'https://immunizebc.ca/vaccination-status-reporting-regulation')}>Find information on the Vaccine Status Reporting Regulation.</a>
                </p>
                <p>
                    <a href="https://immunizebc.ca/vaccine-records" onClick={(e) => this.goToLink(e, 'https://immunizebc.ca/vaccine-records')}>Find information on immunization records.</a>
                </p>
            </div>
        );
    }
}

export default Faq;