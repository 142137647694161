import React from 'react';
import VaccinationStatusIndicator from './VaccinationStatusIndicator';

function App() {
  return (
    <div className="App">
        <VaccinationStatusIndicator />
    </div>
  );
}

export default App;