import React from 'react'
import "./Response.css"

const Response = (props) => {
    let messageClass = "message";
    const colourKey = props.data.MessageStyle;
    if(colourKey === 'G') {
      messageClass += ' green';
    } 
    if (colourKey === 'Y') {
      messageClass += ' yellow';
    }
    if (colourKey === 'R') {
      messageClass += ' red';
    }

    const formattedPhn = dashifyPhn(props.phn);

    let messageBody = '';
    // Don't escape markup coming from the API.
    if(props.data.MessageBody !== null) {
        messageBody = {__html: props.data.MessageBody}
    }
    else {
        messageBody = {__html: "<p>This service is temporarily unavailable – please come back later</p>"}
    }

    return(
        <div className="response">
            <div className="header">
                {props.data.FullName && (
                    <div>
                        <label>Results for:</label>
                        <p>{props.data.FullName}</p>
                    </div>
                )}
                <div>
                    <label>Date of Birth</label>
                    <p>{props.formattedDob}</p>
                </div>
                <div>
                    <label>Personal Health Number</label>
                    <p>{formattedPhn}</p>
                </div>
            </div>
            <div className={messageClass}>
                <h3>{props.data.MessageTitle}</h3>
                <p dangerouslySetInnerHTML={messageBody}></p>
            </div>
        </div>
    );
}

// Format number string to XXXX-XXX-XXX
const dashifyPhn = (phn) => {
    const firstChunk = phn.slice(0, 4);
    const secondChunk = phn.slice(4, 7);
    const thirdChunk = phn.slice(7, 10);
    return `${firstChunk} - ${secondChunk} - ${thirdChunk}`;
}

export default Response;